export default function LoadingModal() {
  return (
    <div className="fixed inset-0 bg-gray-600 z-50 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-60 -my-5 mx-10 sm:mx-auto max-w-sm p-5 border dark:border-black shadow-lg rounded-md bg-white dark:bg-gray-800">
        <div className="flex justify-center flex-col gap-4 items-center">
          <div
            className="
      animate-spin
      rounded-full
      h-32
      w-32
      border-t-8 border-b-8 border-purple-500 dark:border-purple-300
    "
          ></div>
          <div className="text-lg dark:text-white">Loading... </div>
        </div>
      </div>
    </div>
  );
}
