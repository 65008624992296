import LoadingModal from "./loading";

const Fallback = () => {
  return (
    <div className="primary-bg h-full">
      <LoadingModal />
    </div>
  );
};

export default Fallback;
